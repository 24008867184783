<template>
  <div id="changepass" class="tab-pane tab-box">
    <div class="mt-4 login-start-main">
      <div class="mt-4 position-relative numbers-box">
        <h6 class="mb-2">{{ $t("Password") }}</h6>
        <i class="fas fa-lock"></i>

        <input class="form-control mb-0 h50" :type="showpasword ? 'text' : 'password'" :placeholder="$t('Password')"
          aria-label="Password" v-model="$v.typeform.password_current.$model"
          :class="{ 'is-invalid': $v.typeform.password_current.$error }" minlength="6" maxlength="10" />
        <div class="eyeButton" @click="showData()">
          <i v-if="showpasword" class="fas fa-eye-slash"></i><i v-else class="fas fa-eye"></i>
        </div>
        <div v-if="$v.typeform.password_current.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.password_current.required">
            {{ $t("Please enter old password") }}
          </span>
        </div>
      </div>
      <div class="mt-4 position-relative numbers-box">
        <h6 class="mb-2">{{ $t("New Password") }}</h6>
        <i class="fas fa-lock"></i>
        <input class="form-control mb-0 h50" :type="new_showpasword ? 'text' : 'password'"
          :placeholder="$t('New Password')" aria-label="New Password" v-model="$v.typeform.password.$model"
          :class="{ 'is-invalid': $v.typeform.password.$error }" minlength="6" maxlength="10" />
        <div class="eyeButton" @click="newShowPassword">
          <i v-if="new_showpasword" class="fas fa-eye-slash"></i><i v-else class="fas fa-eye"></i>
        </div>
        <div v-if="$v.typeform.password.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.password.required">
            {{ $t("Please enter new password") }}
          </span>
        </div>
      </div>
      <div class="mt-4 position-relative numbers-box">
        <h6 class="mb-2">{{ $t("Repeat Password") }}</h6>
        <i class="fas fa-lock"></i>

        <input class="form-control mb-0 h50" :type="repeat_showpasword ? 'text' : 'password'"
          :placeholder="$t('Repeat Password')" aria-label="Repeat Password"
          v-model="$v.typeform.confirm_password.$model" :class="{ 'is-invalid': $v.typeform.confirm_password.$error }"
          minlength="6" maxlength="10" />
        <div class="eyeButton" @click="repeatShowPassword">
          <i v-if="repeat_showpasword" class="fas fa-eye-slash"></i><i v-else class="fas fa-eye"></i>
        </div>
        <div v-if="$v.typeform.confirm_password.$error" class="invalid-feedback">
          <span v-if="!$v.typeform.confirm_password.required">
            {{ $t("Please re enter your password") }}
          </span>
          <span v-else-if="!$v.typeform.confirm_password.sameAsPassword">
            {{ $t("Passwords must match") }}
          </span>
        </div>
      </div>
    </div>
    <div class="deliveryaddress border-0 mt-5 profile-bottom border-top pt-3">
      <div class="full-btn px-3" id="changepass">
        <button @click="updatePassword">{{ $t("Change Password") }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { languages } from "../../i18n";
export default {
  data() {
    return {
      typeform: {
        password_current: "",
        password: "",
        confirm_password: "",
      },
      showpasword: false,
      new_showpasword: false,
      repeat_showpasword: false,
    };
  },
  validations: {
    typeform: {
      password_current: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    ...mapActions("user", ["changePassword"]),

    showData() {
      if (this.showpasword) {
        this.showpasword = false;
      } else {
        this.showpasword = true;
      }
    },
    newShowPassword() {
      if (this.new_showpasword) {
        this.new_showpasword = false;
      } else {
        this.new_showpasword = true;
      }
    },
    repeatShowPassword() {
      if (this.repeat_showpasword) {
        this.repeat_showpasword = false;
      } else {
        this.repeat_showpasword = true;
      }
    },

    updatePassword() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        this.changePassword({
          password_current: this.typeform.password_current,
          password: this.typeform.password,
          confirm_password: this.typeform.confirm_password,
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.$toasted.success(data.msg, { duration: 2000 });
            this.typeform = {
              password_current: "",
              password: "",
              confirm_password: "",
            };
            this.$v.typeform.$reset();
          } else {
            this.$toasted.error(data.msg, { duration: 2000 });
          }
        });
      }
    },
  },
};
</script>